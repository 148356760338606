










































































































































import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import { SampleConfiguration } from '../../types';
import * as R from 'ramda';
import { TwInput, TwSelect } from '@/app/components';
import { extend, ValidationProvider } from 'vee-validate';
import { maxValueValidator, minValueValidator, requiredValidator } from '@/app/validators';

const DEFAULT_ROWS = 1000;
const DEFAULT_PERCENTAGE = 0.1;

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'SampleConfiguration',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: { type: Object as PropType<SampleConfiguration> },
    },
    components: { ValidationProvider, TwSelect, TwInput },
    setup(props, { emit }) {
        const selectionOptions = [
            { label: 'First', value: 'first', description: 'Take the first rows of the data as sample' },
            { label: 'Last', value: 'last', description: 'Take the last rows of the data as sample' },
            { label: 'Random', value: 'random', description: 'Select random rows from the data as sample' },
        ];
        const sampleType = computed({
            get: (): string => {
                if (!R.isNil(props.configuration?.size?.rowsCount)) return 'rowsCount';
                return 'percentage';
            },
            set: (sizeType: string) => {
                if (sizeType === 'rowsCount') {
                    emit('change', {
                        ...props.configuration,
                        size: { rowsCount: DEFAULT_ROWS, percentage: null },
                    });
                }
                if (sizeType === 'percentage') {
                    emit('change', {
                        ...props.configuration,
                        size: { rowsCount: null, percentage: DEFAULT_PERCENTAGE },
                    });
                }
            },
        });

        const rowsCount: Ref<number | null> = computed({
            get: () => {
                if (!props.configuration?.size || R.isNil(props.configuration.size.rowsCount)) return null;
                return props.configuration.size.rowsCount;
            },
            set: (newRowsCount: number | null | undefined) =>
                emit('change', {
                    ...props.configuration,
                    size: { ...props.configuration?.size, rowsCount: R.isNil(newRowsCount) ? null : newRowsCount },
                }),
        });

        const percentage: Ref<number | null> = computed({
            get: () => {
                if (!props.configuration?.size || R.isNil(props.configuration.size.percentage)) return null;
                return props.configuration.size.percentage * 100;
            },
            set: (newPercentage: number | null | undefined) => {
                emit('change', {
                    ...props.configuration,
                    size: {
                        ...props.configuration?.size,
                        percentage: R.isNil(newPercentage) ? null : newPercentage * 0.01,
                    },
                });
            },
        });

        return { selectionOptions, percentage, rowsCount, sampleType };
    },
});
