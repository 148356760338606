
















































































































































































import { ShortTypeBadge, SvgImage, TailwindTable, TwButton } from '@/app/components';
import { PaginateQuery } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { ChartBarIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { useTaskTableView } from '../../composable/task-table-view';
import { BlockCategory, BlockType, ExecutionStatus, ExecutionType, ExecutionTypeWrapper } from '../../constants';
import { Loop, Task, TaskResultPayload, Workflow } from '../../types';
import EditColumns from './EditColumns.vue';
import SampleDescription from './SampleDescription.vue';

export default defineComponent({
    name: 'TableViewTable',
    props: {
        task: {
            type: Object as PropType<Task>,
            required: true,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        columnsPerTask: {
            type: Object,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        taskMap: {
            type: Map as PropType<Map<string, Task>>,
            required: true,
        },
    },
    components: {
        SvgImage,
        OrbitSpinner,
        TwButton,
        SampleDescription,
        TailwindTable,
        ChartBarIcon,
        EditColumns,
        RefreshIcon,
        ShortTypeBadge,
    },
    setup(props, { emit }) {
        // Pagination
        const currentPage = ref<number>(1);
        const pageSize = 50;
        const workflow = computed(() => props.workflow);

        const structure = computed(() => {
            if (!props.columnsPerTask) return null;
            return props.columnsPerTask[props.task.id];
        });

        const {
            latestTestExecutionSample,
            testDataLastUpdated,
            latestTestExecutionSampleDetails,
            latestTestExecutionStructure,
            latestExecutionType,
            sourceTaskId,
            totalSampleRows,
            loading,
            refetch,
            getResult,
            visibleColumns,
            hiddenColumns,
            structureColumns,
        } = useTaskTableView(props.task.id, structure, pageSize, workflow);

        const sourceTask = computed(() => (sourceTaskId.value ? props.taskMap.get(sourceTaskId.value) : undefined));

        const columnAPI = ref<any>(null);
        const gridAPI = ref<any>(null);
        const defaultColumnDefinition = {
            sortable: true,
            resizable: true,
            flex: 1,
            minWidth: 200,
        };
        const lastRunningExecution = ref<any>(null);

        const filteredResult = ref<boolean>(false);
        const sortBy = ref<{ field: string | null; asc: boolean }>({ field: null, asc: true });
        const editingColumns = ref<boolean>(false);

        const pagination = computed<PaginateQuery>(() => ({
            page: currentPage.value,
            pageSize,
            total: totalSampleRows.value,
        }));

        const totalColumns = computed(() =>
            latestTestExecutionStructure.value ? Object.keys(latestTestExecutionStructure.value).length : null,
        );

        const filterResult = async () => {
            filteredResult.value = true;
            const payload: TaskResultPayload = {
                columns: visibleColumns.value.map((column: any) => column.label),
                sortBy: sortBy.value,
                pageSize: pageSize,
                page: currentPage.value,
            };
            await getResult(payload);
        };

        const columns = computed(() => {
            if (visibleColumns.value) {
                return visibleColumns.value.map((column: any) => ({
                    key: column.label,
                    label: `${column.label}`,
                    type: column.type,
                    sortable: true,
                    isVariable: column.isVariable,
                }));
            }
            return latestTestExecutionStructure.value;
        });

        const isTaskRunning = computed(
            () =>
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === props.task.id,
        );
        const runningExecutionImage = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                if (props.runningExecution.status === ExecutionStatus.Queued) {
                    return 'queue';
                }
                if (props.runningExecution.type === ExecutionType.Dry) {
                    return 'building';
                }
                return 'calculating';
            }
            return null;
        });

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return 'A run is currently in progress';
        });

        const lastUpdatedDateTime = computed(() => {
            if (!R.isNil(testDataLastUpdated.value)) {
                return dayjs(testDataLastUpdated.value).format('DD MMMM YYYY HH:mm:ss');
            }
            return null;
        });

        const lastUpdatedShort = computed(() => {
            if (!R.isNil(testDataLastUpdated.value)) {
                return dayjs(testDataLastUpdated.value).fromNow();
            }
            return null;
        });

        const canRunTestRun = computed(
            () => ![BlockCategory.Output, BlockCategory.Control].includes(props.task.block.category),
        );

        const isTraining = computed(
            () =>
                props.task.block.category === BlockCategory.MachineLearning &&
                props.task.block.type === BlockType.Train,
        );

        const testRun = () => {
            emit('test-run');
        };

        const onGridReady = (params: { api: any; columnApi: any }) => {
            gridAPI.value = params.api;
            columnAPI.value = params.columnApi;
        };

        const changeSort = (sort: { field: string; asc: boolean }) => {
            sortBy.value = sort;
            filterResult();
        };

        const changePage = (page: number) => {
            currentPage.value = page;
            filterResult();
        };

        const updateColumns = (data: { selected: any[]; unselected: any[] }) => {
            visibleColumns.value = R.clone(data.selected);
            hiddenColumns.value = R.clone(data.unselected);
            editingColumns.value = false;
            filterResult();
        };

        const isTaskInLoop = computed(
            (): boolean =>
                !R.isNil(props.workflow.configuration.loops) &&
                !R.isEmpty(props.workflow.configuration.loops) &&
                props.workflow.configuration.loops.some((loop: Loop) => loop.tasks.includes(props.task.id)),
        );

        watch(
            () => props.runningExecution,
            (execution: any) => {
                if (R.isNil(execution) && execution !== lastRunningExecution.value) {
                    refetch();
                    currentPage.value = 1;
                }
                lastRunningExecution.value = execution;
            },
        );

        return {
            S,
            latestTestExecutionSample,
            latestTestExecutionSampleDetails,
            latestExecutionType,
            sourceTask,
            testRun,
            loading,
            onGridReady,
            defaultColumnDefinition,
            runningExecutionImage,
            runningExecutionTooltip,
            isTaskRunning,
            lastUpdatedDateTime,
            lastUpdatedShort,
            canRunTestRun,
            isTraining,
            columns,
            sortBy,
            changeSort,
            pagination,
            changePage,
            filteredResult,
            visibleColumns,
            hiddenColumns,
            totalColumns,
            updateColumns,
            editingColumns,
            totalSampleRows,
            structureColumns,
            isTaskInLoop,
            BlockType,
        };
    },
});
