






































































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { HashtagIcon, CheckCircleIcon, XCircleIcon, ClockIcon } from '@vue-hero-icons/outline';
import { ExecutionMetrics, ExecutionStreamingMetrics } from '../../types';
import { useFilters } from '@/app/composable';

export default defineComponent({
    name: 'ExecutionHistoryStatistics',
    components: {
        HashtagIcon,
        CheckCircleIcon,
        XCircleIcon,
        ClockIcon,
    },
    props: {
        executionMetrics: {
            type: Object as PropType<ExecutionMetrics | ExecutionStreamingMetrics>,
        },
        isLongRunning: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { formatCount, formatSeconds } = useFilters();

        const metrics = computed(() => {
            if (props.isLongRunning) {
                const streamingMetrics = props.executionMetrics as ExecutionStreamingMetrics;
                return {
                    total: {
                        label: 'Total Messages',
                        value: formatCount(streamingMetrics.totalMessages),
                    },
                    successful: {
                        label: 'Processed Messages',
                        value: formatCount(streamingMetrics.processedMessages),
                    },
                    failed: { label: 'Skipped Messages', value: formatCount(streamingMetrics.skippedMessages) },
                    time: { label: 'Execution Time', value: formatSeconds(streamingMetrics.executionTime) },
                };
            }
            const normalMetrics = props.executionMetrics as ExecutionMetrics;
            return {
                total: { label: 'Total Executions', value: formatCount(normalMetrics.totalExecutions) },
                successful: {
                    label: 'Successful Executions',
                    value: formatCount(normalMetrics.successfulExecutions),
                },
                failed: { label: 'Failed Executions', value: formatCount(normalMetrics.failedExecutions) },
                time: { label: 'Avg. Execution Time', value: formatSeconds(normalMetrics.avgExecutionTime) },
            };
        });

        return { metrics };
    },
});
