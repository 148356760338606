var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-1 w-full overflow-hidden"},[_c('portal',{attrs:{"to":"modals"}},[(_vm.showInvalidConfigurationWarning)?_c('ConfirmModal',{attrs:{"title":"Invalid configuration","message":"Analytics pipeline has been edited and current configuration is not valid. Please delete or update current configuration.","okButtonText":"Delete","cancelButtonText":"Keep configuring"},on:{"ok":function($event){return _vm.deleteChartConfig()},"cancel":function($event){_vm.showInvalidConfigurationWarning = false}}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row flex-1"},[(!_vm.isCollapsed)?_c('div',{staticClass:"flex flex-col p-1 overflow-hidden border-r select-none w-80 xl:w-96 bg-neutral-50"},[_c('div',{staticClass:"flex flex-col flex-1 py-3 space-y-3 overflow-hidden"},[_c('div',{staticClass:"flex flex-row items-center justify-between px-4 text-lg font-bold tracking-wide uppercase text-primary-700"},[_c('div',[_vm._v("Visualisations")]),_c('div',{staticClass:"cursor-pointer text-primary-700",attrs:{"title":"Collapse Options"},on:{"click":function($event){_vm.isCollapsed = true}}},[_c('ChevronLeftIcon',{staticClass:"w-6 h-6 my-auto"})],1)]),_c('Scrollbar',{staticClass:"px-4"},[_c('ResultsViewOptions',{key:("" + _vm.refreshDate),attrs:{"tasks":_vm.exportTasks,"isValidWorkflow":!_vm.warningMessage,"visualisations":_vm.visualisations,"read-only":_vm.readOnly,"chart":_vm.chart,"addForTask":_vm.addForTask,"updateChartTypeForVisualisation":_vm.updateChartTypeForVisualisation},on:{"add-for-task":function($event){_vm.addForTask = $event},"change-chart-type":function($event){_vm.updateChartTypeForVisualisation = $event},"set-chart-type":_vm.updateChart,"cancel":function($event){_vm.updateChartTypeForVisualisation = undefined;
                            _vm.addForTask = undefined;}},model:{value:(_vm.selectedVisualisationId),callback:function ($$v) {_vm.selectedVisualisationId=$$v},expression:"selectedVisualisationId"}})],1)],1)]):_c('div',{staticClass:"flex flex-col w-12 overflow-hidden cursor-pointer bg-primary-800",attrs:{"title":"Expand Options"},on:{"mouseenter":function($event){_vm.overviewIconHovered = true},"mouseleave":function($event){_vm.overviewIconHovered = false},"click":function($event){_vm.overviewIconHovered = false;
                _vm.isCollapsed = false;}}},[_c('div',{staticClass:"mt-4 text-white"},[(!_vm.overviewIconHovered)?_c('CollectionIcon',{staticClass:"w-6 h-6 mx-auto"}):_c('ChevronRightIcon',{staticClass:"w-6 h-6 mx-auto"})],1),_c('div',{staticClass:"my-auto font-bold tracking-wider text-white uppercase transform -rotate-90 whitespace-nowrap hover:text-primary-200"},[_vm._v(" Visualisations ")])]),_c('div',{staticClass:"relative flex flex-row flex-1 flex-grow flex-shrink overflow-hidden bg-neutral-100"},[(_vm.showChartTypes)?_c('div',{staticClass:"absolute top-0 bottom-0 left-0 z-10 flex flex-col space-y-1 overflow-hidden bg-primary-200 w-80"},[_c('div',{staticClass:"flex flex-row items-center justify-between px-4 py-3"},[_c('span',{staticClass:"text-lg font-bold tracking-wide uppercase text-primary-700"},[_vm._v("Chart type")]),_c('XIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Cancel"),expression:"`Cancel`"}],staticClass:"w-6 h-6 cursor-pointer text-primary-700",on:{"click":function($event){_vm.updateChartTypeForVisualisation = undefined;
                            _vm.addForTask = undefined;}}})],1),_c('ChartTypes',{attrs:{"existing":!!_vm.selectedVisualisationId},model:{value:(_vm.chart),callback:function ($$v) {_vm.chart=$$v},expression:"chart"}})],1):_vm._e(),(_vm.showChart)?_c('ResultsViewMain',{key:(_vm.isCollapsed + "_" + (JSON.stringify(_vm.chartConfiguration))),attrs:{"readOnly":_vm.readOnly,"chartConfig":_vm.chartConfiguration,"upstreamTaskSample":_vm.testSampleData},on:{"test-run":_vm.testRun}}):_c('div',{staticClass:"flex flex-col items-center justify-start w-full h-full p-8 overflow-hidden"},[(_vm.upstreamTask && _vm.warningMessage && !_vm.isTaskRunning)?_c('GeneralAlert',{attrs:{"text":_vm.warningMessage,"actionCallback":_vm.testRun,"actionTitle":_vm.warningMessage === _vm.WarningMessages.DRY_RUN ? 'Run one now' : '',"alertType":"warning"}}):(!_vm.isTaskRunning)?_c('div',{staticClass:"flex w-full"},[_c('GeneralAlert',{attrs:{"text":_vm.mainPanelMessage,"alertType":"info"}})],1):_vm._e(),(_vm.isTaskRunning)?_c('div',{staticClass:"flex w-full"},[_c('GeneralAlert',{attrs:{"text":_vm.runningExecutionTooltip,"alertType":"info","hasLoader":""}})],1):_vm._e()],1)],1),(_vm.showConfiguration)?_c('div',{staticClass:"flex flex-col w-56 p-1 overflow-hidden border-x bg-neutral-50 md:w-64 lg:w-80 xl:w-96"},[_c('div',{staticClass:"flex flex-row items-center justify-start pt-3 pl-4 pr-6 space-x-1 text-lg font-bold tracking-wide uppercase text-primary-700"},[_c('div',[_vm._v("Configuration")]),(_vm.displayDisabledMarkersAndLabelsIcon || _vm.isChartScatterPlot)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.isChartScatterPlot
                            ? 'The rendering performance of the specific chart may be impacted for a large number of data point results and if specific options are not configured properly. Some examples of these options are Maximum Number of Results, Labels and Markers\' Size.'
                            : 'Markers\' Size and Labels have been reset and disabled in this graph due to a large number of data-points. Data-points are calculated based on the Maximum Number Of Results multiplied by the Number of Series added in the Y-axis.',
                        classes: 'max-w-md break-words',
                        placement: 'top',
                    }),expression:"{\n                        content: isChartScatterPlot\n                            ? 'The rendering performance of the specific chart may be impacted for a large number of data point results and if specific options are not configured properly. Some examples of these options are Maximum Number of Results, Labels and Markers\\' Size.'\n                            : 'Markers\\' Size and Labels have been reset and disabled in this graph due to a large number of data-points. Data-points are calculated based on the Maximum Number Of Results multiplied by the Number of Series added in the Y-axis.',\n                        classes: 'max-w-md break-words',\n                        placement: 'top',\n                    }"}],staticClass:"flex items-center justify-center flex-shrink-0 mx-auto rounded-full sm:mx-0"},[_c('ExclamationCircleIcon',{staticClass:"w-5 h-5 text-secondary-600"})],1):_vm._e()]),(_vm.chartConfiguration && !_vm.isExecutionRunning && !_vm.warningMessage)?_c('ChartConfiguration',{key:_vm.chartConfiguration.chart.getChartType(),attrs:{"availableSeries":_vm.seriesStructure,"read-only":_vm.readOnly,"chartConfig":_vm.chartConfiguration,"lastSavedChartConfiguration":_vm.lastSavedChartConfiguration},on:{"save":_vm.saveVisualisation,"update":_vm.updateChartConfiguration,"reset":_vm.resetChartConfig,"delete":_vm.deleteChartConfig,"markers-and-labels-reset":_vm.resetMarkersAndLabels,"validation-status-change":function($event){_vm.invalidConfiguration = $event}}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }