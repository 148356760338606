










import { WorkflowType } from '@/app/constants';
import { ExecutionHistory } from '@/modules/workflow-designer/components/execution-history';
import { defineComponent } from '@vue/composition-api';
import { useBlocks } from '../../composable';

export default defineComponent({
    name: 'AnalyticsJobsExecutionHistory',
    props: {
        workflowId: {
            type: String,
            required: true,
        },
    },
    components: { ExecutionHistory },
    setup: () => {
        const { blocksMap: blocks } = useBlocks();

        const facetLabelPreprocess = {
            failedBlockId: (id: string) => {
                const block = blocks.value[id];
                return block ? block.name : id;
            },
        };

        return { WorkflowType, facetLabelPreprocess };
    },
});
